import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import OfferCard from './Card';

const Offers = () => {
  const { allOffersJson } = useStaticQuery(query);
  const offers = allOffersJson.edges.map(offer => offer.node);

  return (
    <>
      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div id="partners" className="row justify-content-center">
          <div className="card-deck">
            {offers.map(offer => (
              <div key={ offer.id } className="card">
                <OfferCard card={offer} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const query = graphql`
  query {
    allOffersJson {
      edges {
        node {
          id
          header
          image
          imageClass
          description
        }
      }
    }
  }
`;

export default Offers;
