import React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Typed from 'react-typed';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Partners from '../components/partners';
import Offers from '../components/offers';

const Home = ({ data: { site: { siteMetadata: { description } } } }) => (
  <Layout bodyClass="page-home">
    <SEO title="Home" />
    <Helmet>
      <meta
        name="description"
        content={{ description }}
      />
    </Helmet>
    <div className="intro-home">
      <div className="video-overlay"/>
      <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
        <source src="sg.mp4" type="video/mp4" alt="" width="75%"/>
        <track src="" kind="captions" srcLang="en" label="english_captions" />
      </video>

      <div className="intro-container h-100 row">
        <div className="col-12 text-center my-auto">
          <img src="./pages/labs-white.png" alt="" width="30%"/>
          <h1 className="text-white">
            <Typed
              strings={[
                'Banking-as-a-Service',
                'FinTech',
                'PayTech',
                'RegTech',
                'InsurTech',
              ]}
              loop
              typeSpeed={120}
            />
          </h1>

          <Link className="button button-primary mt-5" to="/#start">
            Benefits
          </Link>


        </div>
      </div>
    </div>

    <div id="start" className="container">
      <div className="row">
        <div className="team col-12 pt-7 text-center">
          <h1>$250k+ Worth of Benefits</h1>
          <p>
            <strong>As one of the fastest growing FinTech startups in the world, Mbanq decided to give back to the community by starting the Mbanq LABS accelerator. We understand the startup game and we are ready to help you to build or accelate your product and raise funding. We partnered with the best companies in Southeast Asia to bring your ideas to life.</strong>
          </p>

          <h1>{'What We Offer'}</h1>
          <Offers />

          <h1>{'Our Partners'}</h1>
          <Partners />
        </div>
      </div>
    </div>

  </Layout>
);

Home.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
  }
`;

export default Home;
